import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SALES_PACKAGE_CARD_FRAGMENT = `
  fragment salesPackageCardFragment on SalesPackage {
    uid
    title
    shortDescription
    variantName
    variantGroup
    price
    rank
    creationTime
    updateTime
    featured
    maximumQuantityPerExhibitor
    _stock"%filter%" {
      uid
      quantity
    }
    _isPurchased"%filter%"
    fileResource {
      ...fileResourceBaseFragment
    }
    childSalesPackages {
      uid
      price
      _stock"%filter%" {
       uid
       quantity
      }
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
